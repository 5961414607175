
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  onIonViewDidLeave,
} from '@ionic/vue';
import { defineComponent, inject, ref, Ref } from 'vue';
import axios from 'axios';
import MessageBox from '@/components/MessageBox.vue';
import { useRouter } from 'vue-router';
import { throttle } from 'lodash';

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    MessageBox,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
  },
  setup() {
    const router = useRouter();
    const usernamePattern = /^[a-zA-Z0-9 ]*$/;
    const errorMsg: Ref<typeof MessageBox | null> = ref(null);
    const usernameText = ref('');
    const setUsername: (name: string) => void = inject('setUsername', () => undefined);

    onIonViewDidLeave(() => {
      errorMsg.value?.close();
    });

    const isValidUsername = (username: string): boolean => {
      return username.length >= 5 && username.length <= 20 && usernamePattern.test(username);
    };

    const onSubmit = throttle(async (): Promise<boolean> => {
      errorMsg.value?.close();

      if (!isValidUsername(usernameText.value)) {
        errorMsg.value?.showMsg(
          'Username has to be between 5 to 20 characters and contains only letters, numbers, and spaces',
        );
        return false;
      }
      try {
        const response = await axios.post(
          process.env.VUE_APP_USER_ENDPOINT_URL + '/v1/user/forgotPassword',
          {
            name: usernameText.value,
          },
        );
        if (response.data.Message === 'Request password reset success') {
          setUsername(usernameText.value);
          router.push({
            name: 'ResetPassword',
            params: { Destination: response.data.Destination },
          });
        } else {
          errorMsg.value?.showMsg('Unable to verify: ' + response.data.Message);
        }
      } catch (error:any) {
        if (error.response) {
          if (error.response.data.Message === 'UserNotFoundException') {
            errorMsg.value?.showMsg('Account not found, please sign up!');
          } else if (error.response.data.Message === 'LimitExceededException') {
            errorMsg.value?.showMsg('Too many attempts');
          } else {
            errorMsg.value?.showMsg('Error: ' + error.response.data.Message);
          }
        } else if (error.request) {
          errorMsg.value?.showMsg('Bad request');
        } else {
          errorMsg.value?.showMsg('Error: ' + error.message);
        }
      }
      return true;
    }, 1000);

    const clickEmailButton = (): void => {
      document.getElementById('emailButton')?.click();
    };

    return {
      clickEmailButton,
      onSubmit,
      errorMsg,
      usernameText,
    };
  },
});
